import type { QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { createContext } from 'react';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { FinanceResult, IReportingPhoenixApi } from './api';
import { ReportingPhoenixApi } from './api';

const ApiContext = createContext<IReportingPhoenixApi | undefined>(undefined);
const {
  useApi: useReportingPhoenixApi,
  ApiProvider: ReportingPhoenixApiProvider,
} = makeApiProvider({
  name: 'ReportingPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new ReportingPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useReportingPhoenixApi,
  ReportingPhoenixApiProvider,
  ApiContext as ReportingPhoenixApiContext,
  FinanceResult,
};

export const getFinanceKey = (nursingHomeId: string | undefined): QueryKey => [
  'GET_FINANCE',
  nursingHomeId,
];

export const useGetFinancePhoenix = ({
  nursingHomeId,
  options,
}: {
  nursingHomeId: string | undefined;
  options?: UseQueryOptions<FinanceResult>;
}): UseQueryResult<FinanceResult> => {
  return useApiQuery(
    useReportingPhoenixApi,
    getFinanceKey(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('Missing nursing home ID');
      }
      return api.getFinance({
        params: {
          nursing_home_id: nursingHomeId,
        },
      });
    },
    {
      ...options,
      enabled: nursingHomeId !== undefined,
    }
  );
};
