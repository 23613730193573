import {
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import type { Coin } from '../GetCashManagementDto';

/**
 * Cash lists are divided into two major groups. Cash lists
 * that are used for daily operational purposes and cash lists
 * that are in the bank or other accounts as a reserve.
 *
 * Operational cash lists can be used to withdraw money from for
 * resident withdrawals and receipt withdrawals. Withdrawing money from a reserve account
 * is only allowed in the context of a transfer, or to adjust the balance.
 *
 * Transfers between cash lists are always allowed.
 */
export enum CashListAccountType {
  /** The cash list is used for daily operational purposes */
  Operational = 'operational',
  /** The cash list */
  Reserve = 'reserve',
}

/**
 * Cash lists can either store cash only, a nominal value (bank account) or both.
 *
 * For now, by default operational cash lists allow both, and reserve cash lists
 * only allow bank accounts.
 */
export enum CashListStorageType {
  Cash = 0b01,
  BankAccount = 0b10,
  CashOrBankAccount = 0b11 /** 0b01 | 0b10 */,
}

/**
 * This structure holds all cash list configurations associated with a nursing home.
 */
export class GetCashListNursingHomeConfigurationDto {
  @IsNotEmpty()
  @IsUUID()
  nursingHomeId!: string;

  @ValidateNested({ each: true })
  cashListConfigurations!: CashListConfigurationDto[];

  /**
   * The cash list id that is used for payout deposits.
   */
  @IsNotEmpty()
  @IsUUID()
  payoutTargetCashListId!: string;

  /**
   * The cash list id that is used for service provider payment withdrawals.
   */
  @IsNotEmpty()
  @IsUUID()
  serviceProviderPaymentTargetCashListId!: string;
}

export class CashListConfigurationDto {
  @IsNotEmpty()
  @IsUUID()
  cashListId!: string;

  @IsNotEmpty()
  @IsEnum(CashListAccountType)
  type!: CashListAccountType;

  @IsNotEmpty()
  @IsEnum(CashListStorageType)
  storageType!: CashListStorageType;

  /** The display name of the cash list */
  @IsNotEmpty()
  @IsString()
  name!: string;

  /** The total amount of the cash list in the minor unit (Cents for euros) */
  @IsNotEmpty()
  @IsInt()
  totalInCent!: number;

  /** The coins stored in the cash list */
  coins!: Coin[];

  @IsNotEmpty()
  @IsString()
  currency!: 'euro';

  /** The amount of money in the bank account in the minor unit (Cents for euros) */
  @IsNotEmpty()
  @IsInt()
  bankAccountAmountInCent!: number;

  @IsNotEmpty()
  @IsDate()
  createdAt!: Date;
}
