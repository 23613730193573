import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

export interface FinanceResult {
  data: {
    nursing_home_id: string;
    total_resident_balance: number;
    mangopay: {
      wallet: number;
      payins: number;
      payouts: number;
    };
    bank: {
      total: number;
    };
    cash: {
      total: number;
    };
    receipts: {
      draft: number;
    };
  };
}

export interface IReportingPhoenixApi extends Api {
  getFinance(dataIn: {
    params: {
      nursing_home_id: string;
    };
  }): Promise<FinanceResult>;
}

export class ReportingPhoenixApi implements IReportingPhoenixApi {
  baseUrl: string;

  getFinance: IReportingPhoenixApi['getFinance'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getFinance = get({
      authContext,
      url: (params) =>
        `${this.baseUrl}/reporting/nursing-home/${params.nursing_home_id}/finance`,
    });
  }
}
